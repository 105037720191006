import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Heading } from '@twilio-paste/heading';
import { UnorderedList, ListItem } from '@twilio-paste/list';
import { Breadcrumb, BreadcrumbItem } from '@twilio-paste/breadcrumb';
import { Table, THead, TBody, Td, Th, Tr } from '@twilio-paste/table';
import { Truncate } from '@twilio-paste/truncate';
import Changelog from '@twilio-paste/breadcrumb/CHANGELOG.md';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { SidebarCategoryRoutes } from '../../../constants';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/breadcrumb"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/breadcrumb/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/breadcrumb" storybookUrl="/?path=/story/components-breadcrumb--anchors" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <Callout mdxType="Callout">
  <CalloutTitle mdxType="CalloutTitle">Using alpha components in your product</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    Alpha components are ready for use in production and components like Breadcrumb won't likely see any API changes.
    The term "Alpha" means there is only basic documentation and no corresponding Sketch symbol at this time. Those will
    eventually be prioritized, but please share your experience using this Alpha component by{' '}
    <Anchor href="https://github.com/twilio-labs/paste/discussions/new" mdxType="Anchor">starting a discussion on GitHub</Anchor>. If
    you’re interested in helping to symbolize this component in Sketch, we’d love your help – please reach out!
  </CalloutText>
        </Callout>
        <h2>{`Guidelines`}</h2>
        <h3>{`About Breadcrumb`}</h3>
        <p>{`Breadcrumb navigation helps the user understand their location in an app or website by providing a trail back to the top level of the website.`}</p>
        <p>{`Breadcrumbs are listed in order and represent the hierarchy of the page in the product. They will include links to parent pages and can optionally show the current page.`}</p>
        <h3>{`Accessibility`}</h3>
        <ul>
          <li parentName="ul">{`The Breadcrumb component includes the accessible `}<inlineCode parentName="li">{`aria-label="breadcrumb"`}</inlineCode>{` attribute.`}</li>
          <li parentName="ul">{`An unlinked BreadcrumbItem includes the accessible `}<inlineCode parentName="li">{`aria-current="page"`}</inlineCode>{` attribute.`}</li>
        </ul>
        <Callout variant="warning" mdxType="Callout">
  <CalloutText mdxType="CalloutText">
    The unlinked <inlineCode>BreadcrumbItem</inlineCode> should <strong>only</strong> be used for the page a user is
    currently on. If it is not the current page, make sure to add the <inlineCode>href</inlineCode> so the{' '}
    <inlineCode>BreadcrumbItem</inlineCode> goes to the appropriate location.
  </CalloutText>
        </Callout>
        <h2>{`Examples`}</h2>
        <h3>{`Default Breadcrumb`}</h3>
        <p>{`The Breadcrumb component uses `}<a parentName="p" {...{
            "href": "/components/anchor"
          }}>{`Anchors`}</a>{` to link back to parent pages by passing an `}<inlineCode parentName="p">{`href`}</inlineCode>{` to each `}<inlineCode parentName="p">{`BreadcrumbItem`}</inlineCode>{`.`}</p>
        <LivePreview scope={{
          Breadcrumb,
          BreadcrumbItem
        }} language="jsx" mdxType="LivePreview">
  {`<Breadcrumb>
  <BreadcrumbItem href="#">Phone Numbers</BreadcrumbItem>
  <BreadcrumbItem href="#">Active Numbers</BreadcrumbItem>
</Breadcrumb>`}
        </LivePreview>
        <h3>{`Breadcrumb with Current Page`}</h3>
        <p>{`To show the current page, use a `}<inlineCode parentName="p">{`BreadcrumbItem`}</inlineCode>{` with no `}<inlineCode parentName="p">{`href`}</inlineCode>{`. This should `}<strong parentName="p">{`not`}</strong>{` be used if there is a visible page title directly below the breadcrumb.`}</p>
        <LivePreview scope={{
          Breadcrumb,
          BreadcrumbItem
        }} language="jsx" mdxType="LivePreview">
  {`<Breadcrumb>
  <BreadcrumbItem href="#">Phone Numbers</BreadcrumbItem>
  <BreadcrumbItem href="#">Active Numbers</BreadcrumbItem>
  <BreadcrumbItem>Germany Hotline B</BreadcrumbItem>
</Breadcrumb>`}
        </LivePreview>
        <h3>{`Breadcrumb with Truncation`}</h3>
        <p>{`Sometimes the text of a breadcrumb can be long. In those situations you can use the Truncate component inside the `}<inlineCode parentName="p">{`BreadcrumbItem`}</inlineCode>{` element.`}</p>
        <LivePreview scope={{
          Breadcrumb,
          BreadcrumbItem,
          Box,
          Truncate
        }} language="jsx" mdxType="LivePreview">
  {`<Breadcrumb>
  <BreadcrumbItem href="#">Phone Numbers</BreadcrumbItem>
  <BreadcrumbItem href="#">
    <Box as="span" display="inline-flex" maxWidth="size10">
      <Truncate title="Some very long text to truncate">Some very long text to truncate</Truncate>
    </Box>
  </BreadcrumbItem>
  <BreadcrumbItem>Germany Hotline B</BreadcrumbItem>
</Breadcrumb>`}
        </LivePreview>
        <h3>{`Breadcrumb with Page Heading`}</h3>
        <p>{`You may choose to use a page Heading as the current page in the breadcrumb.`}</p>
        <LivePreview scope={{
          Breadcrumb,
          BreadcrumbItem,
          Box,
          Heading
        }} language="jsx" mdxType="LivePreview">
  {`<Box>
  <Breadcrumb>
    <BreadcrumbItem href="#">Phone Numbers</BreadcrumbItem>
  </Breadcrumb>
  <Heading as="h2" variant="heading20">My phone number</Heading>
</Box>`}
        </LivePreview>
        <h2>{`Considerations`}</h2>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Add a slash between two BreadcrumbItems" center mdxType="Do">
    <Breadcrumb mdxType="Breadcrumb">
      <BreadcrumbItem href="#" mdxType="BreadcrumbItem">Phone Numbers</BreadcrumbItem>
      <BreadcrumbItem href="#" mdxType="BreadcrumbItem">Buy new number</BreadcrumbItem>
    </Breadcrumb>
  </Do>
  <Dont title="Don't" body="Add a trailing slash" center mdxType="Dont">
    <Breadcrumb mdxType="Breadcrumb">
      <BreadcrumbItem href="#" mdxType="BreadcrumbItem">Phone Numbers</BreadcrumbItem>
      <BreadcrumbItem href="#" mdxType="BreadcrumbItem">Buy new number</BreadcrumbItem>
      <BreadcrumbItem mdxType="BreadcrumbItem" />
    </Breadcrumb>
  </Dont>
        </DoDont>
        <h2>{`Anatomy`}</h2>
        <Box marginBottom="space60" mdxType="Box">
  <Table tableLayout="fixed" mdxType="Table">
    <THead mdxType="THead">
      <Tr mdxType="Tr">
        <Th mdxType="Th">Property</Th>
        <Th mdxType="Th">Default token</Th>
        <Th mdxType="Th">Modifiable?</Th>
      </Tr>
    </THead>
    <TBody mdxType="TBody">
      <Tr mdxType="Tr">
        <Td mdxType="Td">color</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">BreadcrumbItem: $color-text or $color-text-link</ListItem>
            <ListItem mdxType="ListItem">BreadcrumbSeparator: $color-text-weak</ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">font-size</Td>
        <Td mdxType="Td">$font-size-20</Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">line-height</Td>
        <Td mdxType="Td">$line-height-20</Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">margin</Td>
        <Td mdxType="Td">$space-0</Td>
        <Td mdxType="Td">No</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">padding</Td>
        <Td mdxType="Td">
          <UnorderedList marginBottom="space0" mdxType="UnorderedList">
            <ListItem mdxType="ListItem">$space-0</ListItem>
            <ListItem mdxType="ListItem">
              BreadcrumbSeparator:
              <UnorderedList marginBottom="space0" mdxType="UnorderedList">
                <ListItem mdxType="ListItem">padding-left: $space-20</ListItem>
                <ListItem mdxType="ListItem">padding-right: $space-20</ListItem>
              </UnorderedList>
            </ListItem>
          </UnorderedList>
        </Td>
        <Td mdxType="Td">No</Td>
      </Tr>
    </TBody>
  </Table>
        </Box>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/breadcrumb - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Breadcrumb, BreadcrumbItem} from '@twilio-paste/core/breadcrumb';

const BreadcrumbExample: React.FC = () => {
  return (
    <Breadcrumb>
      <BreadcrumbItem href="#">Item One</BreadcrumbItem>
      <BreadcrumbItem href="#">Item Two</BreadcrumbItem>
    </Breadcrumb>
  );
};
`}</code></pre>
        <h4>{`Breadcrumb Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <h4>{`BreadcrumbItem Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`children`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ReactNode`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`href?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`string`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The URL the breadcrumb routes to.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`last?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`If true, removes the `}<inlineCode parentName="td">{`BreadcrumbSeparator`}</inlineCode>{` after the last breadcrumb item.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      